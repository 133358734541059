<template>
  <div id="userOrder" v-loading.fullscreen.lock="fullscreenLoading">
    <Dialog ref="dialog" :config="config" />

    <div class="tabsContainer" style="padding: 20px 20px 0; border-bottom: none" v-show="showDeleteShow === false">
      <el-tabs v-model="form.status" @tab-click="handleClick">
        <el-tab-pane :label="allTabText" name="all"></el-tab-pane>
        <el-tab-pane :label="temporaryTabText" name="temporary"></el-tab-pane>
        <el-tab-pane :label="obligationTabText" name="obligation"></el-tab-pane>
        <!--        <el-tab-pane :label="deleteTabText" name="delete"></el-tab-pane>-->
        <el-tab-pane :label="receivedTabText" name="received"></el-tab-pane>
        <el-tab-pane :label="inspectionTabText" name="inspection"></el-tab-pane>
        <el-tab-pane :label="warehouseTabText" name="warehouse"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 列表区域 -->
    <div style="border-top: none" class="list">
      <div class="searchBox">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input type="text" v-model="form.searchText" @keydown.enter="getData(form)" :placeholder="$fanyi('请输入订单号搜索')" />
        </div>
        <el-date-picker v-model="value1" type="datetimerange" :start-placeholder="$fanyi('开始日期')"
          :range-separator="$fanyi('')" :end-placeholder="$fanyi('结束日期')" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          :default-time="['00:00:00', '23:59:59']" @change="setFromDate">
        </el-date-picker>


        <button class="searchBtn" @click="
          form.page = 1
        getData(form)
          ">
          {{ $fanyi('搜索') }}
        </button>
        <div class="cancelledBtn" @click="showCancelledOrder">
          {{ showDeleteShow === false ? $fanyi('已取消订单') : $fanyi('返回') }}
        </div>
      </div>
      <div class="dataBody">
        <ul class="ohead">
          <!-- No -->
          <li class="oNO" :style="(form.status == 'obligation' ? 'flex:0.15' : '',
            showDeleteShow !== false ? 'flex:0.15' : '')
            ">
            <span class="tableHeadCon">NO</span>
          </li>
          <li class="orderNumber">
            <span class="tableHeadCon" :title="$fanyi('订单号')">{{
              $fanyi('订单号')
            }}</span>
          </li>
          <li class="datePresentation" v-if="form.status != 'temporary'"
            :style="showDeleteShow !== false ? 'flex:0.7' : ''">
            <span class="tableHeadCon" :title="$fanyi('提出日期')">{{
              $fanyi('提出日期')
            }}</span>
          </li>
          <li class="datePresentation" v-if="form.status != 'temporary' && form.status != 'obligation'"
            :style="showDeleteShow !== false ? 'flex:0.7' : ''">
            <span class="tableHeadCon" :title="$fanyi('付款日期')">{{
              form.status == 'delete'
              ? $fanyi('订单取消日期')
              : $fanyi('付款日期')
            }}</span>
          </li>
          <li class="datePresentation" v-if="form.status != 'temporary' &&
              form.status != 'obligation' &&
              form.status != 'delete'
              ">
            <span class="tableHeadCon" :title="$fanyi('购买完成日期')">
              {{ $fanyi('购买完成日期') }}</span>
          </li>
          <li class="upLoad" v-if="['purchase', 'purchased'].indexOf(form.status) != -1">
            <span class="tableHeadCon" :title="$fanyi('导出excel')">{{
              $fanyi('导出excel')
            }}</span>
          </li>
          <li :style="form.status == 'obligation' ? 'flex:0.6' : ''">
            <span class="tableHeadCon" :title="$fanyi('订单状态')">{{
              $fanyi('订单状态')
            }}</span>
          </li>
          <li v-if="['temporary', 'obligation'].indexOf(form.status) != -1">
            <span class="tableHeadCon" :title="$fanyi('操作')">{{
              $fanyi('操作')
            }}</span>
          </li>
        </ul>
        <!-- 表格区域 -->
        <ul class="obody" v-for="(item, index) in tableData.data" :key="$fanyi('表格') + index">
          <li class="oNO" :style="(form.status == 'obligation' ? 'flex:0.15' : '',
            showDeleteShow !== false ? 'flex:0.15' : '')
            ">
            {{ index + 1 }}
          </li>
          <li class="orderNumber">
            <button @click="
              $fun.toPage(
                '/orderDetails?type=' +
                item.statusName +
                '&order_sn=' +
                item.order_sn
              )
              ">
              {{ item.order_sn }}
            </button>
          </li>
          <!-- 提出日期 -->
          <li class="datePresentation" v-if="form.status != 'temporary'"
            :style="showDeleteShow !== false ? 'flex:0.7' : ''">
            {{ item.created_at }}
          </li>
          <li class="datePresentation" v-if="form.status != 'temporary' &&
            form.status != 'obligation' &&
            form.status != 'delete'
            ">
            {{
              item.order_time_node
              ? item.order_time_node.datetime_pay_confirm
                ? item.order_time_node.datetime_pay_confirm
                : '————'
              : '————'
            }}
          </li>
          <!-- 订单取消日期 -->
          <li class="datePresentation" v-if="form.status != 'temporary' && form.status != 'obligation'"
            :style="showDeleteShow !== false ? 'flex:0.7' : ''">
            {{
              item.delete_time
              ? item.delete_time
              : item.order_time_node
                ? item.order_time_node.datetime_purchase_complete
                  ? item.order_time_node.datetime_purchase_complete
                  : '————'
                : '————'
            }}
          </li>
          <!-- <li class="upLoad" v-if="['purchase', 'purchased'].indexOf(form.status) != -1"
            @click="getFapiao(item.order_sn)">
            <img src="../../../../assets/newImg/shancg2.svg" alt="" />
          </li> -->
          <li :style="form.status == 'obligation' ? 'flex:0.6' : ''">
            {{ textConversion(item.statusName) }}
          </li>
          <li v-if="['temporary', 'obligation'].indexOf(form.status) != -1">
            <span v-if="item.statusName == 'obligation' || item.statusName == 'temporary'" style="cursor: pointer"
              @click="delOrder(item.order_sn)" class="quxiao">{{ $fanyi('取消') }}</span>
            <!--            <span v-if="form.status==='obligation'"  style="cursor: pointer;display: inline-block;margin-left: 30px" @click="-->
            <!--                $fun.toPage(-->
            <!--                  '/orderDetails?type=' +-->
            <!--                    item.statusName +-->
            <!--                    '&order_sn=' +-->
            <!--                    item.order_sn-->
            <!--                )-->
            <!--              ">{{-->
            <!--                $fanyi("前往付款")-->
            <!--              }}</span>-->
            <button v-if="form.status === 'obligation' && item.statusName == 'obligation'
              " class="searchBtn" style="margin-left: 30px" @click="
    $fun.toPage(
      '/orderDetails?type=' +
      item.statusName +
      '&order_sn=' +
      item.order_sn
    )
    ">
              {{ $fanyi('前往付款') }}
            </button>
          </li>
        </ul>
      </div>
      <div class="NoDaTa" v-if="tableData.data ? tableData.data.length == 0 : false">
        {{ $fanyi('暂无数据') }}
      </div>
      <div class="allpag">
        <span></span>
        <el-pagination @current-change="handleCurrentChange" :current-page="form.currentPage" :page-size="10"
          :page-sizes="[10, 20, 30, 50]" layout=" prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentMonthFirst,
  getCurrentMonthLast,
} from '../../../../utlis/date'
import Dialog from '../../../../components/public/Dialog'
import axios from 'axios'
import Europess from '../../../../api/wangZhi'
import { Loading } from 'element-ui'
let Europe = Europess + '/client/'
export default {
  name: 'order',
  data() {
    return {
      value1: null,
      form: {
        status: 'all',
        page: 1,
        pageSize: 10,
        searchText: '',
        startTime: '',
        endTime: '',
      },
      loadingInstance: '',
      fullscreenLoading: false,
      tableData: [],
      total: 0,
      tableOption: [], //表格显示哪些选项
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
      allTabText: this.$fanyi('所有订单') + ` (0)`,
      temporaryTabText: this.$fanyi('临时保存的订单') + ` (0)`,
      obligationTabText: this.$fanyi('待处理的订单') + ` (0)`,
      // deleteTabText: this.$fanyi("已取消的订单") + ` (0)`,
      receivedTabText: this.$fanyi('待收货') + ` (0)`,
      inspectionTabText: this.$fanyi('检品中') + ` (0)`,
      warehouseTabText: this.$fanyi('入库完了') + ` (0)`,
      all: 0,
      showDeleteShow: false,
      ordersSumList: {
        temporary: 0,
        delete: 0,
        purchased: 0,
        purchase: 0,
        obligation: 0,
        offer: 0,
        all: 0,
      }, //商品列表各种类商品数据
    }
  },
  components: { Dialog },
  computed: {},
  watch: {
    $route(to, from) {
      // this.form.status = "all";
      this.form.page = 1
      this.form.pageSize = 10
      // 默认显示当月
      // this.form.startTime = getCurrentMonthFirst() + " 00:00:00";
      // this.form.endTime = getCurrentMonthLast() + " 23:59:59";
      // this.value1 = [
      //   getCurrentMonthFirst() + " 00:00:00",
      //   getCurrentMonthLast() + " 23:59:59",
      // ];
      if (this.$route.query.status) {
        this.form.status = this.$route.query.status
        this.$router.push({
          query: {},
        })
        this.getData(this.form)
      }
      this.ordersSum()
    },
    deep: true,
  },
  created() {
    this.form.page = 1
    this.form.pageSize = 10

    if (this.$route.query.status) {
      this.form.status = this.$route.query.status
      this.$router.push({
        query: {},
      })
      this.getData(this.form)
    } else {
      this.getData(this.form, true)
    }
    this.loadingInstance = Loading.service()
    this.ordersSum()
  },
  methods: {
    textConversion(value) {
      // 把接口返回的状态名转换成西班牙语;
      let list = [
        {
          key: 'temporary',
          value: this.$fanyi('预购订单'),
        },
        {
          key: 'offer',
          value: this.$fanyi('报价中'),
        },
        {
          key: 'obligation',
          value: this.$fanyi('等待付款'),
        },
        {
          key: 'purchase',
          value: this.$fanyi('正在购买'),
        },
        {
          key: 'purchased',
          value: this.$fanyi('购买完成'),
        },
        {
          key: 'Pending financial confirmation',
          value: this.$fanyi('等待财务确认'),
        },
        {
          key: 'cancel',
          value: this.$fanyi('已取消(订单状态)'),
        },
        {
          key: 'delete',
          value: this.$fanyi('已删除'),
        },
        {
          key: 'shelves',
          value: this.$fanyi('上架中'),
        },
        {
          key: 'warehouse',
          value: this.$fanyi('上架完了'),
        },
        {
          key: 'shipping',
          value: this.$fanyi('出货完成'),
        },
      ]
      for (let i in list) {
        if (list[i].key == value) {
          return list[i].value
        }
      }

      return value
    },
    handleClick(tab) {
      this.form.page = 1
      this.getData(this.form)
      this.loadingInstance = Loading.service()
    },

    setFromDate() {

      if (this.value1) {
        this.form.startTime = this.value1[0]
        this.form.endTime = this.value1[1]
      } else {
        this.form.startTime = ''
        this.form.endTime = ''
      }
    },
    //展示已取消订单
    showCancelledOrder() {
      this.showDeleteShow = !this.showDeleteShow


      if (this.showDeleteShow === true) {
        this.form.status = 'delete'
        this.form.page = 1

        this.getData(this.form)
      } else {
        this.form.status = 'all'
        this.form.page = 1
        this.getData(this.form)
      }
    },
    // 获取每个订单数量
    ordersSum() {
      this.$api
        .ordersSum()
        .then((res) => {
          if (res.data.length == 0) return (this.ordersSumList.all = 0)
          this.allTabText = this.$fanyi('所有订单') + ` (${res.data.all})`
          this.temporaryTabText =
            this.$fanyi('临时保存的订单') + ` (${res.data.temporary})`
          this.obligationTabText =
            this.$fanyi('待处理的订单') + ` (${res.data.obligation})`
          this.receivedTabText =
            this.$fanyi('待收货') + ` (${res.data.purchased})`
          this.inspectionTabText =
            this.$fanyi('检品中') + ` (${res.data.inspection})`
          this.warehouseTabText =
            this.$fanyi('入库完了') + ` (${res.data.warehouse})`
          this.ordersSumList = res.data

        })
        .catch((err) => { })
    },
    // 获取数据，传fir代表页面打开时请求数据的场合
    getData(datas, fir) {
      this.tableData = []
      this.$api
        .orderList(datas)
        .then((res) => {
          //预购订单有数据的时候默认展示预购订单，否则展示全部订单
          if (fir == true && res.data.data.length == 0) {
            this.form.status = 'all'
            this.getData(this.form)
          }

          this.tableData = res.data
          this.total = res.data.total
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            this.loadingInstance.close()
          })

          this.form.currentPage = res.data.currentPage
          this.$forceUpdate()
        })
        .catch((err) => { })
    },
    // 删除订单,根据订单状态分为硬删和软删
    delOrder(order_sn) {
      this.$refs.dialog.open(
        this.$fanyi('是否取消当前订单?'),
        () => {
          let datas = {
            order_sn: order_sn,
            deleteReason: this.$fanyi('删除'),
          }


          this.$api.orderRecycling(datas).then((res) => {

            if (res.code != 0) return
            this.getData(this.form)
            this.ordersSum()
          })
          // 全部取消
          // if (this.form.status == 'temporary') {
          //   this.$api.orderTampDelete(datas).then((res) => {

          //     if (res.code != 0) return

          //     this.getData(this.form)
          //     this.ordersSum()
          //   })
          // } else {
          //   this.$api.orderRecycling(datas).then((res) => {

          //     if (res.code != 0) return
          //     this.getData(this.form)
          //     this.ordersSum()
          //   })
          // }
        },
        () => { }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getData(this.form)
    },
  },
}
</script>
<style lang="scss" scoped>
.NoDaTa {
  font-size: 14px;
  color: #909399;
  text-align: center;
  height: 80px;
  line-height: 80px;
  border-left: solid 1px #ededed;
  border-right: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
}

#userOrder {
  @import '../../../../css/mixin';
  width: 1060px;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
  // border: 1px solid #e2e2e2;
  overflow: hidden;

  button {
    cursor: pointer;
  }

  /deep/.el-range-separator {
    width: 3px;
    height: 20px;
    // background-color: pink;
    background: url('../../../../assets/date.png') no-repeat center;
  }

  /deep/ .el-date-editor {
    position: relative;

    .el-input__icon {
      display: none;
    }
  }

  .tabsContainer {
    border-radius: 0;
    border-bottom: 1px solid #e2e2e2 !important;

    /deep/ .el-tabs__active-bar .is-top {
      min-width: 68px !important;
    }

    /deep/ .el-tabs__nav-wrap::after {
      display: none;
    }
  }

  .list {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px !important;
    border-radius: 0;
  }

  .rowAndCenter {
    display: flex;
    align-items: center;
  }

  >nav {
    height: 50px;
    display: flex;

    >span {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 130px;
      height: 50px;
      padding: 10px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      font-size: 12px;
      text-align: center;

      color: #000000;
      line-height: 16px;

      cursor: pointer;

      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }

      .label {
        display: inline-block;
        margin-right: 7px;
      }

      .numberCount {
        width: 25px;
        height: 25px;
        background: #fbbb21;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        line-height: 25px;
      }
    }
  }

  >div {
    // min-height: 720px;
    background: #ffffff;
    // border: 1px solid #e1e1e1;
    border-top: none;
    padding: 20px 20px 37px 20px;
    border-radius: 6px;

    .searchBox {
      display: flex;
      margin-bottom: 20px;

      .myInput {
        width: 380px;
        height: 40px;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        // overflow: hidden;
        margin-right: 10px;

        >i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        >input {
          border: 1px solid #e2e2e2;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;

          &:focus {
            border-color: #1e2997;
          }
        }
      }

      /deep/.el-date-editor {
        border-radius: 4px;
        width: 350px;
        height: 40px;
        margin-right: 20px;
        line-height: 32px !important;

        .el-input__inner {
          padding: 0px !important;
          border-radius: 4px;
        }

        .el-range-input {
          font-size: 14px;
          border-radius: 4px;
        }

        .el-range-separator {
          width: 50px;
          line-height: 23px;
        }

        .el-range__icon {
          line-height: 25px;
        }
      }

      /deep/ .el-input__inner {
        border-radius: 4px;
      }

      .searchBtn {
        width: 80px;
        height: 40px;
        background: #ff730b;
        border-radius: 4px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;

        font-size: 14px;
        line-height: 32px;
        text-align: center;
      }

      .cancelledBtn {
        width: 140px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #ff730b;

        font-size: 14px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        margin-left: 120px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ff730b;
      }
    }

    .dataBody {

      // margin-bottom: 40px;
      >ul {
        display: flex;

        // width: 1100px;
        >li {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          // line-height: 56px;
          font-size: 14px;
          text-align: center;
          color: #000000;

          // 设置一列
          &.oNO {
            flex: 0.33;
          }

          &.orderNumber {
            flex: 0 0 210px;
          }

          &.datePresentation {
            flex: 0 0 185px;
            text-align: center;
          }
        }
      }

      ul.ohead {
        height: 56px;
        background: #f0f0f0;

        li {
          overflow: hidden;
        }

        .tableHeadCon {
          cursor: default;
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
        }
      }

      ul.obody {
        height: 60px;
        margin: 0 -1px -1px 0;
        border: 1px solid #ededed;

        li {
          border-right: solid 1px #ededed;
          border-bottom: solid 1px #ededed;
          margin: 0 -1px -1px 0;
        }

        .quxiao {
          width: 120px;
          height: 40px;
          background: #ffffff;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          line-height: 40px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #698cff;
        }

        .searchBtn {
          width: 120px;
          height: 40px;
          background: #ff730b;
          border-radius: 4px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;

          line-height: 40px;

          color: #ffffff;
          font-size: 14px;
        }

        .oNO {
          flex: 0.33;
        }

        .orderNumber {
          button {
            font-size: 14px;
            color: #ff730b;
            line-height: 20px;
            background-color: white;

            &:hover {
              text-decoration: underline;
              color: #ff730b;
            }
          }
        }

        .datePresentation {
          text-align: center;
        }

        .upLoad {
          img {
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
      }
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 25px;

      .el-pagination {
        display: flex;
        align-items: center;
      }

      >a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        // background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 0;
      }

      /deep/.btn-prev {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-right: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /deep/.btn-next {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-left: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 10px;
        // border-radius: 0;
        min-width: 20px !important;
        font-size: 14px;
        height: 30px;
        color: #999999;
        // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

        &:hover {
          color: #ff730b;
        }

        &.active {
          color: #ff730b;
          border-bottom: 2px solid #ff730b;
          width: 20px !important;
        }
      }
    }
  }
}
</style>
